<template>
  <div>
    
    

    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>
     
          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col>

        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          
          <b-row>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Inicial Verba:"
            >
            <b-form-datepicker
                 id="PeridoIniVerba"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoIniVerba"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Final Verba:"
            >
            <b-form-datepicker
                 id="PeridoFinVerba"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoFinVerba"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>


            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Inicial Venda:"
            >
            <b-form-datepicker
                 id="PeridoIniVenda"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoIniVenda"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Final Venda:"
            >
            <b-form-datepicker
                 id="PeridoFinVenda"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoFinVenda"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>



     
 
          </b-row>

          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Vendedor:" >
                <multiselect
                    v-model="CodVend"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodVendOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Coordenador:" >
                <multiselect
                    v-model="CodCoord"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodCoordOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Gerente:" >
                <multiselect
                    v-model="CodGer"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodGerOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>


          
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Região:" >

                  <multiselect
                    v-model="CodReg"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodRegOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

          

          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Tipo de Operação:"  >

                  <multiselect
                    v-model="TipOper"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="TipOperOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>


            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Holding(Perfil Parceiro):"  >

                  <multiselect
                    v-model="CODTIPPARC"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CODTIPPARCOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>


          </b-row>

        <b-row>
            


        <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
              <div class="DivBotaoLadoLado" >
               <b-button
              variant="danger"
              type="button"
              @click="$router.push('../Relatorios')"
            >
              <span>RETORNAR</span>
            </b-button>
              </div>

              <div class="DivBotaoLadoLado" >
            <b-button v-b-toggle="'collapse-filtro'"
              variant="primary"
              type="submit"
              @click="ConsultarRelatorio"
            >
              <span>CONSULTAR</span>
            </b-button>
              </div>
              
          </b-col>


         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>


      
    <div class="col-12" v-if="TabRelAcompanhamentoVerbaPosition >= 0">
            <div class="card mb-4">
              <div class="card-body pb-60" >
                
                
              <div v-if="TabRelAcompanhamentoVerbaPosition == 0">
                
                <div id="TabRelAcompanhamentoVerba">

                <h4 class="fw-bolder mb-1">Detalhe</h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelAcompanhamentoVerba"
                  :fields="TabRelAcompanhamentoVerbafields"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                  
                >

                 <template #cell(DTMOV)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(VLRNOTA)="row">
                      {{ 'R$ ' + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRNOTA_TOTAL)="row">
                      {{ 'R$ ' + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(PERC)="row">
                      {{ TextFormat(row.value,'percent') }}
                 </template>


                <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="6">
                       Total
                      </td>
  
                      <td class="bg-secondary text-white" colspan="4" > 
                        {{ 'R$ ' + TextFormat(TabRelAcompanhamentoVerbaTotal('VLRNOTA'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" colspan="2"> 
                       {{ TextFormat(TabRelAcompanhamentoVerbaTotal('PERC'),'percent')}}
                      </td>

                     

                    </tr>
                 </template>


                </b-table>
                </div>

                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelAcompanhamentoVerba','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>
               

             </div>
            </div>
    </div>



  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
  BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BFormDatepicker,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      RelName: '',
      RelRota: '',
      TipoConsulta: '',
      TabRelAcompanhamentoVerba: [],
      TabRelAcompanhamentoVerbafields: [
       { key: 'Vendedor', label: 'Vendedor', sortable: true, tdClass: 'td200'  },
       { key: 'CODPARC', label: 'Cod.Parceiro', sortable: true,tdClass: 'td100' },
       { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },
       { key: 'NUNOTA', label: 'Nº Unico', sortable: true,tdClass: 'td150' },
       { key: 'NUMNOTA', label: 'Nº Nota', sortable: true,tdClass: 'td150' },       
       { key: 'DTMOV', label: 'Data Movimento', sortable: true,tdClass: 'td100' },
       { key: 'VLRNOTA', label: 'Valor Nota', sortable: true,tdClass: 'td200' },
       { key: 'NOMEUSU', label: 'Usuário de Inclusão', sortable: true,tdClass: 'td200' },        
       { key: 'NUMCONTRATO', label: 'Contrato', sortable: true,tdClass: 'td200' },
       { key: 'VLRNOTA_TOTAL', label: 'Venda(R$) ', sortable: true,tdClass: 'td200' },
       { key: 'PERC', label: 'Investimento (Verba X Faturamento)', sortable: true,tdClass: 'td150' },
       { key: 'OBSERVACAO', label: 'Observação', sortable: false,tdClass: 'td200' },
       
        
      ],
      TipOper: [],
      TipOperOption: [],
      CODTIPPARC: [],
      CODTIPPARCOption: [],
      PeridoIniVerba: '',
      PeridoFinVerba: '',
      PeridoIniVenda: '',
      PeridoFinVenda: '',
      CodVend: [],
      CodVendOption: [],     
      CodCoord: [],
      CodCoordOption: [],     
      CodGer: [],
      CodGerOption: [],     
      CodReg: [],
      CodRegOption: [],
      exportarParaSelected: 'Exporte',
      exportarParaOptions: [
        { value: 'Exporte', text: 'Exporte' },
        { value: 'XLSX', text: 'Excel' },
      ],
      TabRelAcompanhamentoVerbaPosition: -1,
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Vendedor`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.CodVendOption = response.data
            this.CodCoordOption = response.data
            this.CodGerOption = response.data

          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Regiao`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CodRegOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }


      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=TOP`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.TipOperOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }

       if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=PerfilParceiro`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CODTIPPARCOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }


      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorio(OrigemConsulta) {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      this.showFiltro = false
      const usuario = this.$session.get('usuario')
      let notification = ""
      this.FiltroRelatorio = []
      //Fim Dados Base Relatorio

      //Inicio Limpa Dados Retorno
      this.TabRelAcompanhamentoVerba = []
      
      // Fim Limpa Dados Retorno
      
      //Inicio Volta Todos Niveis para posição inicial
      this.TabRelAcompanhamentoVerbaPosition = 0
      //Fim Volta Todos Niveis para posição inicial


      //Inicio Pega parametros de Parametros
       if(this.PeridoIniVerba != "")
       {
         const Filtro = {
           parametro: 'PERIODO_VERBA_INI',
           valor: this.PeridoIniVerba,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.PeridoFinVerba != "")
       {
         const Filtro = {
           parametro: 'PERIODO_VERBA_FIN',
           valor: this.PeridoFinVerba,
         }
         this.FiltroRelatorio.push(Filtro)
       }

   
      if(this.PeridoIniVenda != "")
       {
         const Filtro = {
           parametro: 'PERIODO_VENDA_INI',
           valor: this.PeridoIniVenda,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.PeridoFinVenda != "")
       {
         const Filtro = {
           parametro: 'PERIODO_VENDA_FIN',
           valor: this.PeridoFinVenda,
         }
         this.FiltroRelatorio.push(Filtro)
       }


      if(this.CodVend != null){
        if(Array.isArray(this.CodVend) == false)
        {
          const Filtro = {
            parametro: 'CODVEND',
            valor: this.CodVend.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }

      if(this.CodCoord != null){
         if(Array.isArray(this.CodCoord) == false)
         {
           const Filtro = {
             parametro: 'CODCOORD',
             valor: this.CodCoord.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }
  
      if(this.CodGer != null){
         if(Array.isArray(this.CodGer) == false)
         {
           const Filtro = {
             parametro: 'CODGER',
             valor: this.CodGer.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }
          

      this.CodReg.forEach(Item => {
        const Filtro = {
          parametro: 'CODREG',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })
        
       if(this.TipOper != null){
         if(Array.isArray(this.TipOper) == false){
             const Filtro = {
             parametro: 'CODTIPOPER',
             valor: this.TipOper.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }


      if(this.CODTIPPARC != null){
         if(Array.isArray(this.CODTIPPARC) == false){
             const Filtro = {
             parametro: 'CODTIPPARC',
             valor: this.CODTIPPARC.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }
   
      //Fim Pega parametros de Parametros
      

      let ParametroJson = ''
      let URLpost = ''
      

    if(notification === '') {
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelAcompanhamentoVerba?CODUSU=${usuario.codusu}&Nivel=NV1`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabRelAcompanhamentoVerba = response.data
            
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }



     if(notification === '') {
       Spinner.EndLoad()
     }

    },
    TabRelAcompanhamentoVerbaTotal(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'VLRNOTA':
          Valor = this.TabRelAcompanhamentoVerba.reduce((accum, TabRelAcompanhamentoVerba) => { return accum + TabRelAcompanhamentoVerba.VLRNOTA }, 0.0)
        break

        case 'VLRNOTA_TOTAL':
          Valor = this.TabRelAcompanhamentoVerba.reduce((accum, TabRelAcompanhamentoVerba) => { return accum + TabRelAcompanhamentoVerba.VLRNOTA_TOTAL }, 0.0)
        break

        case 'PERC':
          Valor = this.TabRelAcompanhamentoVerba.reduce((accum, TabRelAcompanhamentoVerba) => { return accum + TabRelAcompanhamentoVerba.PERC }, 0.0)
          
        break

             
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    ExportarDoc (ElementToExport,DocName) {
      let DataExport = ""
      switch (ElementToExport) {
                case 'TabRelAcompanhamentoVerba':
                DataExport = this.TabRelAcompanhamentoVerba
                DocName = 'RelAcompanhamentoVerba'
                break
      }    
      UtilVUE.ExportDoc(this.exportarParaSelected,DocName,ElementToExport,DataExport)
      this.exportarParaSelected = 'Exporte'
      return

    },
  },
  computed: {
  },
  mounted() {
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.PeridoIni = new Date()
      this.PeridoFin = new Date()
      this.MostraReport()
      this.ConsultarOpcoes()
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
